<div class="container cs--cyan">
    <!-- HEADER -->
    <app-header></app-header>

    <div class="messages messages--info mx-auto mb-4" role="alert" aria-atomic="true" *ngIf="shouldShowAlert('volunteers')">
        <i class="icon icon-info"></i>
        <p [innerHTML]="'layout.notes.volunteers' | translate"></p>
        <span class="ms-2 icon-cross align-self-start" (click)="hideAlert('volunteers')"></span>
    </div>

    <!-- CONTENT -->
    <router-outlet></router-outlet>

    <!-- FOOTER -->
    <app-footer></app-footer>
</div>
