<div class="container-fluid">
  <!-- Page title -->
  <h3>{{'profile.changePassword.title' | translate}}</h3>

  <hr>

  <!-- Explanation about the conditions for a password -->
  <div class="alert alert-info">
    <ul>
      <li>{{'profile.changePassword.conditions.li1' | translate}}</li>
      <li>{{'profile.changePassword.conditions.li2' | translate}}</li>
      <li>{{'profile.changePassword.conditions.li3' | translate}}</li>
    </ul>
  </div>

  <hr>

  <!-- Form to change password -->
  <form [formGroup]="formGroup" (ngSubmit)="submitChangePassword(formGroup.value)">

    <!-- Old Password -->
    <div class="row form-group">
      <label class="col-lg-3 control-label" for="oldPassword">
        {{'profile.changePassword.form.oldPassword' | translate}}
      </label>
      <div class="col-lg-8">
        <input class="form-control" id="oldPassword" type="password" formControlName="oldPassword" >
      </div>
    </div>

    <!-- Error if 'old' password was wrong -->
    <div class="row">
      <div class="alert alert-error" *ngIf="wrongOldPassword">
        {{'profile.changePassword.form.wrongOldPassword' | translate}}
      </div>
    </div>

    <!-- Password -->
    <div class="row form-group">
      <label class="col-lg-3 control-label" for="password">
        {{'profile.changePassword.form.password' | translate}}</label>
      <div class="col-lg-8">
        <input class="form-control" id="password" type="password" formControlName="password" >
      </div>

      <!-- If password is valid: provide a glyphicon-ok -->
      <div class="col-lg-1 glyphicon glyphicon-ok" *ngIf="isPasswordValid()"></div>
    </div>

    <!-- Confirm password -->
    <div class="row form-group">
      <label class="col-lg-3 control-label" for="confirmPassword">
        {{'profile.changePassword.form.confirmPassword' | translate}}</label>
      <div class="col-lg-8">
        <input class="form-control" id="confirmPassword" type="password" formControlName="confirmPassword">
      </div>

      <!-- If password is valid, and it matches the confirmation password: provide
           another glyphicon -->
      <div class="col-lg-1 glyphicon glyphicon-ok" *ngIf="isPasswordValid() && confirmPasswordMatches()"></div>
    </div>

    <!-- Error if something went wrong while updating the password -->
    <div class="row">
      <div class="alert alert-error" *ngIf="otherErrorOnUpdating">
        {{'profile.changePassword.form.otherErrorOnUpdating' | translate}}
      </div>
    </div>

    <!-- Buttons to confirm/cancel -->
    <div class="row">
      <div class="col-md-11">

        <div class="form-group pull-right">
          <!-- Save changes -->
          <button type="submit" class="btn btn-primary btn-left"
                  [disabled]="disabledSubmitButton(formGroup.value)">
            {{'profile.changePassword.form.confirmButton' | translate}}
          </button>

          <!-- Cancel changes -->
          <button type="button" class="btn btn-secondary btn-space" (click)="cancelChangePassword()">
            {{'profile.changePassword.form.cancelButton' | translate}}
          </button>
        </div>

      </div>
    </div>
 </form>
</div>
