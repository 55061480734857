<div *ngIf="authority !== undefined; else waitingForServer">
    <!-- Title -->
    <h3>
        {{('management.authorities.users.title' | translate).replace('{authorityName}', authority.authorityName)}}
    </h3>
    <hr>
    <div>
        <div class="messages messages--info">
            <i class="icon-info" aria-hidden="true"></i>
            <div>
                {{('management.authorities.users.info' | translate).replace('{authorityName}', authority.authorityName)}}
                {{'management.authorities.users.info2' | translate}}
            </div>
        </div>
    </div>

    <div class="row responsive-table" *ngIf="usersInAuthorityObs | async as users">
        <div class="table-wrapper">
            <table class="w-100" *ngIf="users.length > 0; else noUsersInAuthority">
                <thead>
                <tr>
                    <th scope="col">{{'management.users.searchResult.table.id' | translate}}</th>
                    <th scope="col">{{'management.users.searchResult.table.firstName' | translate}}</th>
                    <th scope="col">{{'management.users.searchResult.table.lastName' | translate}}</th>
                    <th scope="col">{{'management.users.searchResult.table.email' | translate}}</th>
                    <th scope="col">
                        <!-- Delete -->
                    </th>
                </tr>
                </thead>

                <tbody>
                <tr *ngFor="let user of users">
                    <td>{{ user.userId }}</td>
                    <td>{{ user.firstName }}</td>
                    <td>{{ user.lastName }}</td>
                    <td>
                        {{ user.mail.toLowerCase() }}
                    </td>
                    <td class="hover" (click)="prepareToDeleteUserFromAuthority(user, deleteUserFromAuthorityModal)">
                      <span>
                        <i class="icon-cross" aria-hidden="true"></i>
                      </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <button class="button button-primary" (click)="prepareToAddUserToAuthority(addUserToAuthorityModal)">
        {{'management.authorities.users.addNewUser' | translate}}
    </button>
</div>

<ng-template #waitingForServer>
    <div class="messages messages--info">
        {{'general.waitingForServer' | translate}}
    </div>
</ng-template>

<ng-template #noUsersInAuthority>
    <div class="messages messages--warning">
        {{'management.authorities.users.noUsersInAuthority' | translate}}
    </div>
</ng-template>

<ng-template #addUserToAuthorityModal>
    <app-modal>
        <h2 modal-header>
            {{'management.authorities.users.addUserToAuthorityModal.title' | translate}}
        </h2>
        <div modal-body>
            <app-search-user icon="icon-plus" (selectedUser)="addUserToAuthority($event)">

            </app-search-user>
        </div>
        <div modal-message-footer>
            <div class="messages messages--info" *ngIf="successAddingAuthority === null || successSearchingUsers === null">
                {{'general.waitingForServer' | translate}}
            </div>

            <div class="messages messages--success" *ngIf="successAddingAuthority === true">
                {{'management.authorities.users.addUserToAuthorityModal.success' | translate}}
            </div>

            <div class="messages messages--error" *ngIf="successAddingAuthority === false">
                {{'management.authorities.users.addUserToAuthorityModal.error' | translate}}
            </div>

            <div class="messages messages--error" *ngIf="successSearchingUsers === false">
                {{'management.authorities.users.addUserToAuthorityModal.errorSearching' | translate}}
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- Modal to ask whether the manager is sure to remove the user from the authority -->
<ng-template #deleteUserFromAuthorityModal>
    <app-modal>
        <h2 modal-header>
            {{'management.authorities.users.deleteUserFromAuthorityModal.title' | translate}}
        </h2>

        <!-- Body -->
        <div modal-body>
            <div>
                <p>
                    {{('management.authorities.users.deleteUserFromAuthorityModal.body' | translate).replace('{authorityName}', authority.authorityName)}}
                </p>
                <ul *ngIf="userPreparedToDelete !== undefined">
                    <li><b>{{('management.users.searchResult.table.id' | translate) + ':'}}</b> {{userPreparedToDelete.userId}}</li>
                    <li><b>{{('management.users.searchResult.table.firstName' | translate) + ':'}}</b> {{userPreparedToDelete.firstName}}</li>
                    <li><b>{{('management.users.searchResult.table.lastName' |translate)}}</b> {{userPreparedToDelete.lastName}}</li>
                </ul>
            </div>
        </div>

        <!-- Footer -->
        <div modal-footer>
            <button type="button" class="button button-primary"
                    (click)="deleteUserFromAuthority(userPreparedToDelete.userId, authority.authorityId)">
                {{'general.buttons.delete' | translate}}
            </button>
            <button type="button" class="button button-secondary" (click)="closeModal()">
                {{'general.buttons.cancel' | translate}}
            </button>
        </div>

        <div modal-message-footer>
            <div class="messages messages--info" *ngIf="successDeletingAuthority === null">
                {{'general.waitingForServer' | translate}}
            </div>

            <div class="messages messages--success" *ngIf="successDeletingAuthority === true">
                {{'management.authorities.users.deleteUserFromAuthorityModal.success' | translate}}
            </div>

            <div class="messages messages--error" *ngIf="successDeletingAuthority === false">
                {{'management.authorities.users.deleteUserFromAuthorityModal.error' | translate}}
            </div>
        </div>
    </app-modal>
</ng-template>
