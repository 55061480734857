<div *ngIf="location | async as location" >
    <!-- The editor for the dutch reminder -->
    <div>
        <h3>{{'management.locationDetails.reminder.dutchReminder' | translate}}</h3>
        <ckeditor [(ngModel)]="model.dutch"
                  [editor]="editor"
                  [config]="config">
        </ckeditor>
    </div>

    <!-- The editor row for the english reminder -->
    <div class="mt-3">
        <h3>{{'management.locationDetails.reminder.englishReminder' | translate}}</h3>
        <ckeditor [(ngModel)]="model.english"
                  [editor]="editor"
                  [config]="config">
        </ckeditor>
    </div>

    <!-- Buttons -->
    <div class="d-flex gap-3 mt-3" >
        <button class="button button-primary" (click)="updateButtonClick()" [disabled]="!isModelUpdatable()">
            {{'management.locationDetails.reminder.updateButton' | translate}}
        </button>
        <button class="button button-secondary" (click)="cancelButtonClick()" [disabled]="!isModelUpdatable()">
            {{'management.locationDetails.reminder.cancelButton' | translate}}
        </button>
    </div>


    <!-- Feedback on updating the reminders -->
    <div class="mt-3">
        <div class="messages messages--info" *ngIf="showUpdateSuccess === null">
            {{'general.waitingForServer' | translate}}
        </div>
        <div class="messages messages-success" *ngIf="showUpdateSuccess === true">
            {{'management.locationDetails.reminder.successUpdating' | translate}}
        </div>
        <div class="messages messages--error" *ngIf="showUpdateSuccess === false">
            {{'management.locationDetails.reminder.errorUpdating' | translate}}
        </div>
    </div>
</div>
