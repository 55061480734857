<div *ngIf="statsObs | async as stats">
    <!-- Title -->
    <h3>
        {{'management.stats.title' | translate}}
    </h3>
    <hr>
    <div *ngIf="loading" class="alert alert-info">
        {{'management.stats.loading' | translate}}
    </div>
    <!-- If an error has occurred while fetching the stats, show the error -->
    <div *ngIf="errorOnRetrievingStats" class="alert alert-error">
        {{'management.stats.error' | translate}}
    </div>
    <ul class="nav-tabs">
        <li><a href="" [class]="activeTab === 0 ? 'active' : ''"
               (click)="changeTab($event, 0)">{{'management.stats.nav.occupancy' | translate}}</a></li>
        <li><a href="" [class]="activeTab === 1 ? 'active' : ''"
               (click)="changeTab($event, 1)">{{'management.stats.nav.overviewLocation' | translate}}</a></li>
        <li><a href="" [class]="activeTab === 2 ? 'active' : ''"
               (click)="changeTab($event, 2)">{{'management.stats.nav.overviewInstitution' | translate}}</a></li>
    </ul>
    <div *ngIf="activeTab === 0">
        <!-- Info -->
        <div class="messages messages--info">
            <i class="icon-info" aria-hidden="true"></i>

            {{'management.stats.info' | translate}}
        </div>

        <!-- Specific date selector  -->
        <div class="mb-2">
            <input type="checkbox" [(ngModel)]="showSpecificDateInsteadOfCurrentOrNext" (change)="onShowChange()"/> {{'management.stats.showSpecificDateInsteadOfCurrentOrNext' | translate}}
            <div *ngIf="showSpecificDateInsteadOfCurrentOrNext" class="mt-3">
                <input type="date" [(ngModel)]="date" (change)="onDateChange()"/>
            </div>
        </div>

        <!-- Some more info -->
        <p [innerHTML]="('management.stats.summaryOccupancy' | translate).replace('{occupied}', occupancy).replace('{total}', total).replace('{totalNotReservable}', totalNotReservable)">

        </p>
        <p>
            {{'management.stats.noteLocationOverview' | translate}}
        </p>
        <!-- Occupancy waffles -->
        <div *ngIf="!loading && !errorOnRetrievingStats">
            <div class="row gy-5" *ngIf="stats.length > 0; else noLocations">
                <div *ngFor="let locationStat of stats" class="col-12 col-lg-4">
                    <app-waffle [locationStat]="locationStat" class="waffle-chart"></app-waffle>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="activeTab === 1">
        <div *ngIf="locations | async as locations">
            <!-- Date and location selector -->
            <form>
                <div class="row">
                    <div class="col">
                        <label for="location" class="control-label">
                            {{'management.stats.location' | translate}}
                            <select class="form-control" id="location" name="location" [(ngModel)]="selectedLocationId" (change)="onLocationOverviewChange()">
                                <option *ngFor="let location of locations" [value]="location.locationId">
                                    {{location.name}}
                                </option>
                            </select>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label for="locationOverviewFrom">
                            {{'management.stats.from' | translate}}
                            <input class="form-control" id="locationOverviewFrom" name="locationOverviewFrom" type="date" [(ngModel)]="locationOverviewFrom" (change)="onLocationOverviewChange()"/>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label for="locationOverviewFrom">
                            {{'management.stats.to' | translate}}
                            <input class="form-control" id="locationOverviewTo" name="locationOverviewTo" type="date" [(ngModel)]="locationOverviewTo" (change)="onLocationOverviewChange()"/>
                        </label>
                    </div>
                </div>
            </form>
        </div>
        <hr>
        <div *ngIf="locationOverviewStat">
            <!-- Info -->
            <div class="messages messages--info">
                <i class="icon-info" aria-hidden="true"></i>

                {{'management.stats.noteLocationOverview' | translate}}
            </div>
            <h4>
                {{'management.stats.reservationsTotal' | translate}}</h4>
            <p>
                {{('management.stats.reservationsTotalMsg' | translate).replace('{total}', locationOverviewStat.reservationsTotal + '')}}
            </p>
            <h4>
                {{'management.stats.totalPerInstitution' | translate}}
            </h4>
            <!-- Stats -->
            <app-chart
                [reservations]="locationOverviewStat.reservationsPerDayPerHOI">
            </app-chart>
            <div class="responsive-table table-wrapper fullwidth">
                <table class="w-100">
                    <thead>
                    <tr>
                        <th>{{'management.stats.institution' | translate}}</th>
                        <th>{{'management.stats.total' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of locationOverviewStat.reservationsTotalPerHOI | keyvalue">
                        <td>{{item.key}}</td>
                        <td>{{item.value}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <h4>{{'management.stats.totalPerDay' | translate}}</h4>
            <div class="responsive-table table-wrapper fullwidth">
                <table style="width: 100%">
                    <thead>
                    <tr>
                        <th>{{'management.stats.date' | translate}}</th>
                        <th>{{'management.stats.total' | translate}}</th>
                        <th *ngFor="let institution of institutions">{{institution}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of locationOverviewStat.reservationsPerDayPerHOI | keyvalue">
                        <td>{{item.key}}</td>
                        <td>{{locationOverviewStat.reservationsPerDay[item.key]}}</td>
                        <td *ngFor="let institution of institutions">{{item.value[institution] || 0}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div *ngIf="activeTab === 2">
        <form>
            <div class="row">
                <div class="col">
                    <label for="institutionLocations">
                        {{'management.stats.institutionLocations' | translate}}
                        <select class="form-control" name="selectedInstitutionLocations" id="institutionLocations" [(ngModel)]="selectedInstitutionLocations"
                                (change)="onInstitutionOverviewChange()">
                            <option *ngFor="let institution of institutionsExtended" [value]="institution">
                                {{institution}}
                            </option>
                        </select>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="institutionStudents">
                        {{'management.stats.institutionStudents' | translate}}
                        <select class="form-control" id="institutionStudents" name="selectedInstitutionStudents" [(ngModel)]="selectedInstitutionStudents"
                                (change)="onInstitutionOverviewChange()">
                            <option *ngFor="let institution of institutionsExtended" [value]="institution">
                                {{institution}}
                            </option>
                        </select>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="institutionOverviewFrom">
                        {{'management.stats.from' | translate}}
                        <input class="form-control" id="institutionOverviewFrom" type="date" name="institutionOverviewFrom" [(ngModel)]="institutionOverviewFrom" (change)="onInstitutionOverviewChange()"/>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="institutionOverviewFrom">
                        {{'management.stats.to' | translate}}
                        <input class="form-control" id="institutionOverviewTo" type="date" name="institutionOverviewTo" [(ngModel)]="institutionOverviewTo" (change)="onInstitutionOverviewChange()"/>
                    </label>
                </div>
            </div>
        </form>
        <hr>
        <div *ngIf="institutionOverviewStat">
            <!-- Info -->
            <div class="messages messages--info">
                <i class="icon-info" aria-hidden="true"></i>

                {{'management.stats.noteInstitutionOverview' | translate}}
            </div>
            <h4>
                {{('management.stats.outgoingStudentsPerHOI' | translate).replace('{institution}', selectedInstitutionStudents)}}
            </h4>
            <div class="responsive-table table-wrapper fullwidth">
                <table class="w-100">
                    <thead>
                    <tr>
                        <th>{{'management.stats.institution' | translate}}</th>
                        <th>{{'management.stats.total' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of institutionOverviewStat.outgoingStudentsPerHOI | keyvalue">
                        <td>{{item.key}}</td>
                        <td>{{item.value}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <h4>
                {{('management.stats.incomingStudentsPerHOI' | translate).replace('{institution}', selectedInstitutionLocations)}}
            </h4>
            <div class="responsive-table table-wrapper fullwidth">
                <table class="w-100">
                    <thead>
                    <tr>
                        <th>{{'management.stats.institution' | translate}}</th>
                        <th>{{'management.stats.total' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of institutionOverviewStat.incomingStudentsPerHOI | keyvalue">
                        <td>{{item.key}}</td>
                        <td>{{item.value}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #noLocations>
    <div class="messages messages--info">
        <i class="icon-info" aria-hidden="true"></i>
        {{'management.stats.noOpenLocations' | translate}}
    </div>
</ng-template>
