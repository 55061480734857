<zxing-scanner id="scanner"
    [torch]="torchEnabled"
    [device]="deviceCurrent"
    [formats]="formatsEnabled"
    [tryHarder]="tryHarder"
    (permissionResponse)="onHasPermission($event)"
    (deviceChange)="onDeviceChange($event)"
    (scanSuccess)="onCodeResult($event)"
    (camerasFound)="onCamerasFound($event)"
    (torchCompatible)="onTorchCompatible($event)">
</zxing-scanner>
