<!--
    The information page is provided through Markdown because it is a static
    page. If, at certain points, the page needs to be updated, markdown is easy
    to use for everybody. And we dont have to change any code.
    The markdown files used are provided in `assets/md/information.<lang>.md`
-->
<mat-tab-group>
    <mat-tab label="{{'information.student' | translate}}">
        <div>
            <markdown src="assets/md/user-manuals/man-student.{{ translate.currentLang }}.md"></markdown>
        </div>
    </mat-tab>
    <mat-tab label="{{'information.supervisor' | translate}}" *ngIf="showSupervisors || showManagement || showAdmin">
        <div>
            <markdown src="assets/md/user-manuals/man-volunteer.{{ translate.currentLang }}.md"></markdown>
        </div>
    </mat-tab>
    <mat-tab label="{{'information.manager' | translate}}" *ngIf="showManagement">
        <div>
            <markdown src="assets/md/user-manuals/man-management.{{ translate.currentLang }}.md"></markdown>
        </div>
    </mat-tab>
    <mat-tab label="{{'information.admin' | translate}}" *ngIf="showAdmin">
        <div>
            <markdown src="assets/md/user-manuals/man-admin.{{ translate.currentLang }}.md"></markdown>
        </div>
    </mat-tab>
</mat-tab-group>


