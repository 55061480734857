<div class="panel-group" *ngIf="volunteerObs | async as volunteers;">
    <!-- Heading -->
    <app-accordeon>
        <div class="accordion--button" data-toggle="collapse" attr.aria-controls="accordion--{{ location.locationId }}" [attr.aria-expanded]="!collapsed" accordion-button>
            <div class="hover" fxLayout="row" fxLayoutAlign="space-between center">
                <h4 class="panel-title">
                    <a>
                        {{ ('management.locationDetails.title' | translate).replace('{locationName}', location.name) }}
                    </a>
                </h4>
                <h4>
                    {{ volunteers.length }} {{('management.aside.volunteers' | translate).toLowerCase()}}
                </h4>
            </div>
        </div>
        <!-- Body -->
        <div class="panel-collapse accordion--content " [class.collapse]="collapsed" id="accordion--{{location.locationId}}" accordion-content>
            <div class="panel-body responsive-table">
                <div class="table-wrapper">
                    <table class="w-100" *ngIf="!showEmpty(volunteers)">
                        <thead>
                            <tr>
                                <th scope="col">{{'management.users.searchResult.table.id' | translate}}</th>
                                <th scope="col">{{'management.users.searchResult.table.firstName' | translate}}</th>
                                <th scope="col">{{'management.users.searchResult.table.lastName' | translate}}</th>
                                <th scope="col">{{'management.users.searchResult.table.institution' | translate}}</th>
                                <th scope='col'>
                                    <!-- Details -->
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of volunteers">
                                <td>{{user.userId}}</td>
                                <td>{{user.firstName}}</td>
                                <td>{{user.lastName}}</td>
                                <td>{{user.institution}}</td>
                                <td class="hover" style="padding-left: 10px; padding-right: 10px; padding-top: 10px">
                                    <span (click)="deleteVolunteer(user)">
                                        <i class="icon-cross" aria-hidden="true"></i>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div *ngIf="showEmpty(volunteers)">
                        <div class="messages messages--warning">
                            <i class="icon-exclamation" aria-hidden="true"></i>
                            {{'management.volunteers.no-volunteers' | translate}}
                        </div>
                    </div>

                    <button class='button button-primary mt-3' (click)="showAdd(confirm)">{{'management.volunteers.addNewButton' |translate}}</button>
                </div>
            </div>
        </div>
    </app-accordeon>
</div>

<ng-template #confirm>
    <app-modal>
        <h2 modal-header>
            {{'management.volunteers.addNewHeader' | translate}}
        </h2>
        <div modal-body>
            <app-search-user [icon]="'icon-plus'" (selectedUser)="addVolunteer($event)"></app-search-user>
        </div>
    </app-modal>
</ng-template>
