<div class="responsive-table fullsize">
    <div class="table-wrapped fullsize">
        <table class="table table-striped w-100">
            <thead> <!-- Table header -->
            <tr>
                <th *ngFor="let column of this.tabularData.columns; let i = index; trackBy: trackByMethod" scope="col">
                    {{ column.columnHeader | translate }}
                </th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let dataPoint of tabularData.data" [ngClass]="getCssClasses(dataPoint)">
                <td *ngFor="let column of tabularData.columns; let i = index; trackBy: trackByMethod">
                    <ng-template *ngIf="column.type === 'contentColumn'" [ngTemplateOutlet]="contentColumn"
                                 [ngTemplateOutletContext]="{ column: column, dataPoint: dataPoint, index: i }">
                    </ng-template>

                    <ng-template *ngIf="column.type === 'actionColumn'" [ngTemplateOutlet]="actionColumn"
                                 [ngTemplateOutletContext]="{ column: column, dataPoint: dataPoint, index: i }">
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>


<ng-template #contentColumn let-column="column" let-data="dataPoint">
    {{ column.columnContent(data) }}
    {{ translateColumnContent(column, data) | translate }}

</ng-template>

<ng-template #actionColumn let-column="column" let-data="dataPoint" let-index="index">
    <ng-container *ngIf="column.columnContent(data) as action">
        <div [class.action]="!action.disabled" (click)="onAction(index, data)" *ngIf="action.actionType === 'string'">
            {{ action.actionContent }}
        </div>

        <div [class.action]="!action.disabled" (click)="onAction(index, data)"
             *ngIf="action.actionType === 'icon' && !action.disabled">
            <i class="icon-{{action.actionContent}}" aria-hidden="true"></i>
        </div>

        <div class="action" (click)="onAction(index, data)" *ngIf="action.actionType === 'button'">
            <button class="button button-{{action.buttonClass}}" aria-hidden="true"
                    [disabled]="action.disabled">{{action.actionContent}}</button>
        </div>
    </ng-container>
</ng-template>
