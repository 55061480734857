<ng-container>
    <!-- Overview of the user -->
    <h3>
        {{('management.users.userDetails.form.title' | translate).replace('{name}', user.firstName + ' ' + user.lastName)}}
    </h3>
    <hr>
    <form [formGroup]="detailsFormGroup">
        <!-- AUGent ID -->
        <div class="row form-group">
            <div class="col">
                <label class="control-label" for="id">
                    {{'management.users.userDetails.form.id' | translate}}
                    <input class="form-control" id="id" type="text" formControlName="id">
                </label>
            </div>
        </div>

        <div class="row">
            <!-- First name -->
            <div class="col">
                <label class="control-label" for="firstName">
                    {{'management.users.userDetails.form.firstName' | translate}}
                    <input class="form-control" id="firstName" type="text" formControlName="firstName">
                </label>
            </div>
            <!-- Last name -->
            <div class="col">
                <label class="control-label" for="lastName">
                    {{'management.users.userDetails.form.lastName' | translate}}
                    <input class="form-control" id="lastName" type="text" formControlName="lastName">
                </label>
            </div>
        </div>

        <!-- Mail -->
        <div class="row">
            <div class="col">
                <label class="control-label" for="mail">
                    {{'management.users.userDetails.form.mail' | translate}}
                    <input class="form-control" id="mail" type="text" formControlName="mail">
                </label>
            </div>
        </div>

        <!-- Penalty points (calculated) -->
        <div class="row">
            <div class="col">
                <label class="control-label" for="penaltyPoints">
                    {{'management.users.userDetails.form.penaltyPoints' | translate}}
                    <input class="form-control" id="penaltyPoints" type="text" formControlName="penaltyPoints">
                </label>
            </div>
        </div>

        <!-- Institution -->
        <div class="row">
            <div class="col">
                <label class="control-label" for="institution">
                    {{'management.users.userDetails.form.institution' | translate}}
                    <input class="form-control" id="institution" type="text" formControlName="institution">
                </label>
            </div>
        </div>
    </form>

    <form [formGroup]="settingsFormGroup">
        <!-- Receive mail confirming reservations 24hrs -->
        <div class="row">
            <div class="col">
                <input class="form-control" id="receiveMailConfirmation" type="checkbox" formControlName="receiveMailConfirmation"> {{'profile.overview.form.receiveMailConfirmation' | translate}}
            </div>
        </div>
    </form>
    <div class="row" *ngIf="showImpersonate()">
        <div class="col">
            <button class="button button-secondary" (click)="impersonate(user)">
                {{"management.users.userDetails.form.impersonate" | translate}}
            </button>
        </div>
    </div>
</ng-container>
