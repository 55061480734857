<div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px">
    <label fxFlex="0 1 calc(90% - 20px)">
        <input [disabled]="disabled" class="form-control input-{{this.model === null && !this.disabled }}" type="date"
               [(ngModel)]="modelDateAsString"
               (ngModelChange)="onNewDate()" [min]="modelMinAsString" [max]="modelMaxAsString"/>
    </label>
    <label fxFlex="0 1 calc(10% - 20px)">
        <input [disabled]="disabled" class="form-control input-{{this.model === null && !this.disabled}}" type="time"
               [(ngModel)]="modelTimeAsString"
               (ngModelChange)="onNewDate()"/>
    </label>
</div>
