<div class="container-fluid" id="login">
    <div class="row">
        <div class="col">
            <div class="px-4 py-5">
                <h2 class="mb-0 mt-0">
                    {{'login.title' | translate}}
                </h2>

                <div class="row">
                    <div class="col-12 col-md-6">
                        <h3 class="mb-4">
                            {{ 'login.students' | translate }}
                        </h3>
                        <div class="d-flex flex-column align-items-start gap-3">
                            <a href="{{casFlowTriggerUrl}}?callbackUrl={{getCallbackUrl()}}" class="btn btn-secondary"
                               (click)="loginButtonClicked()">
                                {{'login.casLogin' | translate}}
                            </a>
                            <a href="{{hoGentFlowTriggerUrl}}?callbackUrl={{getCallbackUrl()}}"
                               class="btn btn-secondary"
                               (click)="loginButtonClicked()">
                                {{'login.hoGentLogin' | translate}}
                            </a>
                            <a href="{{arteveldeHSFlowTriggerUrl}}?callbackUrl={{getCallbackUrl()}}"
                               class="btn btn-secondary"
                               (click)="loginButtonClicked()">
                                {{'login.arteveldeHSLogin' | translate}}
                            </a>
                            <a href="{{odiseeFlowTriggerUrl}}?callbackUrl={{getCallbackUrl()}}"
                               class="btn btn-secondary"
                               (click)="loginButtonClicked()">
                                {{'login.odiseeLogin' | translate}}
                            </a>
                            <a href="{{lucaFlowTriggerUrl}}?callbackUrl={{getCallbackUrl()}}" class="btn btn-secondary"
                               (click)="loginButtonClicked()">
                                {{'login.lucaLogin' | translate}}
                            </a>
                            <a href="{{kulFlowTriggerUrl}}?callbackUrl={{getCallbackUrl()}}" class="btn btn-secondary"
                               (click)="loginButtonClicked()">
                                {{'login.kuLeuvenLogin' | translate}}
                            </a>
                            <p class="clarification">
                                {{ 'login.students-clarification' | translate }}
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <h3 class="mb-4">
                            {{ 'login.others' | translate }}
                        </h3>
                        <div class="d-flex flex-column align-items-start gap-3">
                            <a href="{{stadGentFlowTriggerUrl}}?callbackUrl={{getCallbackUrl()}}"
                               class="btn btn-secondary"
                               (click)="loginButtonClicked()">
                                {{'login.stadGentLogin' | translate}}
                            </a>
                            <a href="{{otherFlowTriggerUrl}}?callbackURL={{getCallbackUrl()}}" class="btn btn-secondary"
                               (click)="loginButtonClicked()">
                                {{'login.otherLogin' | translate}}
                            </a>
                            <p class="clarification">
                                {{ 'login.others-clarification' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-8" id="image-container"
             style="background-image: url('assets/images/welcome/welcome.jpg')">
            <div id="display">
                <h1>{{'login.appTitle' | translate}}</h1>
                <h2>{{'login.about' | translate}}</h2>
            </div>
        </div>
    </div>
</div>
