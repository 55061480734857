<div>
    <h3>
        {{'management.volunteers.title' | translate}}
    </h3>
    <hr>
    <!-- Overview of the location -->
    <div *ngIf="manageableLocationsObs | async as manageableLocations; else loadingOrError">
        <app-volunteer-management-panel *ngFor="let mLocation of manageableLocations" [location]="mLocation"></app-volunteer-management-panel>
    </div>

    <ng-template #loadingOrError>
        <div class="messages messages--error" *ngIf="errorSubject | async; else loading">
            <i class="icon-exclamation" aria-hidden="true"></i>

            {{'management.volunteers.errorOnLoadingManageableLocations' | translate}}
        </div>

        <ng-template #loading>
            <div class="messages messages--info">
                <i class="icon-info" aria-hidden="true"></i>

                {{'general.waitingForServer' | translate}}
            </div>
        </ng-template>
    </ng-template>
</div>
