<!-- Show table with received penalties, if the user has any. Else, congratulate him/her -->
<div *ngIf="penalties.penalties.length > 0; else noPenalties">
    <!-- Table title -->
    <b *ngIf="showTitle">{{ "profile.penalties.table.title" | translate }}</b>
    <app-management-table
        [stateless]="false"
        [data]="penalties.penalties"
        [mapper]="getTableMapper()">
    </app-management-table>
</div>
<ng-template #noPenalties>
    <div class="messages messages--info">
        <i class="icon-info" aria-hidden="true"></i>
        {{ "profile.penalties.table.noPenalties" | translate }}
    </div>
</ng-template>
