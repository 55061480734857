<div *ngIf="locationObs | async as location; else loadingOrErrorForLocation">
    <div class="d-flex justify-content-between align-items-center">
        <h3 class="m-0">
            {{ ('scan.locationDetails.title' | translate).replace('{locationName}', location.name) }}
        </h3>
        <div *ngIf="locationReservationObs | async as reservations;">
            <button class="button button-primary" (click)="scanner.open()"  *ngIf="reservations.length > 0">
                {{'scan.phone' | translate}}
            </button>
        </div>
    </div>

    <div *ngIf="locationReservationObs | async as reservations; else loadingOrError">
        <div *ngIf="reservations.length > 0; else noUsers">

            <div class="messages messages--info">
                <i class="icon-info" aria-hidden="true"></i>
                {{"scan.warn-unattended" | translate}}
                <br/>
                {{"scan.handheld-hint" | translate}}
            </div>

            <app-location-reservations [currentTimeSlot]="location.currentTimeslot" [locationReservations]="reservations" [lastScanned]="lastScanned" [isManagement]="false">

            </app-location-reservations>
        </div>
    </div>

</div>

<app-modal #scanner>
    <h2 modal-header>
        {{ 'scan.phone' | translate }}
    </h2>
    <div *ngIf="locationReservationObs | async as reservations" modal-body>
        <div *ngIf="!reservation" class="messages messages--info">
            <i class="icon-info" aria-hidden="true"></i> {{'scan.mobile-hint' | translate}}
        </div>

        <app-scanner [class.hide]="reservation || error" (scanSuccess)="scanUser(reservations, $event)">

        </app-scanner>
    </div>
    <div modal-footer>
        <div class="scanned-user" *ngIf="reservation">
            <p>
                User: {{reservation.user.firstName}} {{reservation.user.lastName}}
            </p>
            <p>
                ID: {{ reservation.user.userId }}
            </p>
            <div class="d-flex gap-3">
                <button class="button button-primary" (click)="confirm()">Confirm</button>
                <button class="button button-secondary" (click)="reservation = null">Cancel</button>
            </div>
        </div>
        <div class="messages messages--error" *ngIf="error">
            <i class="icon-info" aria-hidden="true"></i>
            <span>This user doesn't have a reservation. Please check in the list below.</span> <button class="button button-primary" (click)="error = null">Confirm</button>
        </div>
    </div>
</app-modal>

<ng-template #loadingOrError>
    <div *ngIf="loadingError | async; else loading" class="messages messages--error">
        {{'scan.locationDetails.errorMessageOnLoadingUsers' | translate}}
    </div>
    <ng-template #loading>
        <div class="messages messages--info">
            {{'general.waitingForServer' | translate}}
        </div>
    </ng-template>
</ng-template>

<ng-template #loadingOrErrorForLocation>
    <div *ngIf="locationLoadingSubject | async; else loading" class="messages messages--error">
        {{'scan.locationDetails.errorMessageOnLoadingLocation' | translate}}
    </div>

    <ng-template #loading>
        <div class="messages messages--info">
            {{'general.waitingForServer' | translate}}
        </div>
    </ng-template>
</ng-template>

<ng-template #noUsers>
    <div class="messages messages--warning">
        <i class="icon-cross" aria-hidden="true"></i>
        {{'scan.locationDetails.noUsersToScanWarning' | translate}}
    </div>
</ng-template>
